.Panel {
    border: 1px solid rgb(200,200,200);
    margin-left: auto;
    margin-right: auto;
}

.Panel-title {
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(200,200,200);
    background-color: rgb(230,230,230);
    padding: 7px 10px;
    text-align: left;
}

.Panel-content {
    padding: 7px 10px;
    text-align: left;
}