

.Chart {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left : 20px;
    margin-right: 20px;
    width: 90%;
    height : 90%;
}

.InnerChart {
    max-width: 90%;
    min-height : 90%;
}